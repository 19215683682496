import { createUseStyles } from "react-jss"
import { Theme } from "../theme/theme"

const SpacerStyle = createUseStyles({
  spacer: {
    zIndex: "-1",
    position: "relative",
    margin: 0,
    padding: 0,
    border: "1px solid",
    borderColor: ({ theme }) => (theme as Theme).grid.fill,
    transition: " border-color .12s ease, background .12s ease, height .12s ease"
  },
  fontSpacer: {
    zIndex: "-1",
    position: "relative",
    margin: 0,
    padding: 0,
    border: "1px solid transparent",
    transition: " border-color .12s ease, background .12s ease, height .12s ease"
  },
  hidding: {
    position: "relative",
    margin: 0,
    padding: 0,
    border: "1px solid",
    borderColor: "transparent",
    boxSizing: "border-box",
    MozBoxSizing: "border-box",
    WebkitBoxSizing: "border-box",
  },
  bigNumber: {
    color: ({ theme }) => (theme as Theme).grid.text,
    padding: "7px 0 9px",
    fontFamily: "iA Writer",
    fontWeight: "normal",
    fontSize: "15px",
    lineHeight: "24px",
    letterSpacing: "-0.014px",
    margin: "0",
    position: "absolute",
    right: "8px",
    bottom: "0",
  },
  midNumber: {
    color: ({ theme }) => (theme as Theme).grid.text,
    padding: "3px 0 5px",
    fontFamily: "iA Writer",
    fontWeight: "normal",
    fontSize: "15px",
    lineHeight: "24px",
    letterSpacing: "-0.014px",
    margin: "0",
    position: "absolute",
    right: "8px",
    bottom: "-2px",
  },
  number: {
    color: ({ theme }) => (theme as Theme).grid.text,
    padding: "3px 0 3px",
    fontFamily: "iA Writer",
    fontWeight: "normal",
    fontSize: "15px",
    lineHeight: "24px",
    letterSpacing: "-0.014px",
    margin: "0",
    position: "absolute",
    right: "8px",
    bottom: "-4px",
  },
  smallNumber: {
    color: ({ theme }) => (theme as Theme).grid.text,
    padding: "7px 0 1px",
    fontFamily: "iA Writer",
    fontWeight: "normal",
    fontSize: "15px",
    lineHeight: "24px",
    letterSpacing: "-0.014px",
    margin: "0",
    position: "absolute",
    right: "8px",
    bottom: "-6px",
  },
})

export default SpacerStyle
